const MANT_URLS = {
  users: 'users.php',
  projects: 'projects.php',
  phases: 'phases.php',
  articles: 'articles.php',
  families: 'families.php',
  presuposts: 'presuposts.php',
  tickets: 'tickets.php',
  docMessages: 'doc-messages.php',
  notification: 'notification.php',
  checkouts: 'checkouts.php',
  invoices: 'invoices.php',
  events: 'events.php',
  pool: 'pool.php',
  recoverCodes: 'recover-codes.php'
}

const requests = {
  fetchTest: 'test.php',
  fetchUserWithEmail: {
    url: MANT_URLS.users,
    option: 'searchByEmail'
  },
  createUser: {
    url: MANT_URLS.users,
    option: 'add'
  },
  fetchProjectWithUserId: {
    url: MANT_URLS.projects,
    option: 'searchByUserId'
  },
  fetchProjectWithId: {
    url: MANT_URLS.projects,
    option: 'fetch-by-id'
  },
  fetchPhases: {
    url: MANT_URLS.phases,
    option: 'fetch'
  },
  fetchProjectPhases: {
    url: MANT_URLS.phases,
    option: 'fetchProjectPhases'
  },
  fetchProjectDocsByPhase: {
    url: MANT_URLS.projects,
    option: 'fetchProjectDocsByPhase'
  },
  fetchProjectNotificationByPhase: {
    url: MANT_URLS.projects,
    option: 'fetchProjectNotificationByPhase'
  },
  fetchProjectNotification: {
    url: MANT_URLS.projects,
    option: 'fetchProjectNotification'
  },
  updateProjectPhase: {
    url: MANT_URLS.phases,
    option: 'update'
  },
  createProjectNotification: {
    url: MANT_URLS.projects,
    option: 'addNotification'
  },
  updateProject: {
    url: MANT_URLS.projects,
    option: 'update'
  },
  updateUser: {
    url: MANT_URLS.users,
    option: 'update'
  },
  changeAvatar: {
    url: MANT_URLS.users,
    option: 'change-avatar'
  },
  uploadPhaseImage: {
    url: MANT_URLS.phases,
    option: 'add-phase-image'
  },
  uploadPhaseLink: {
    url: MANT_URLS.phases,
    option: 'add-phase-link'
  },
  changePhaseName: {
    url: MANT_URLS.phases,
    option: 'change-phase-image-name'
  },
  removePhaseImage: {
    url: MANT_URLS.phases,
    option: 'remove-phase-image'
  },
  changeVerifiedByDoc: {
    url: MANT_URLS.phases,
    option: 'change-verifiedby-doc'
  },
  getDocObservation: {
    url: MANT_URLS.phases,
    option: 'get-doc-observation'
  },
  setDocObservation: {
    url: MANT_URLS.phases,
    option: 'set-doc-observation'
  },
  fetchArticles: {
    url: MANT_URLS.articles,
    option: 'fetch'
  },
  fetchFamilies: {
    url: MANT_URLS.families,
    option: 'fetch'
  },
  fetchArticlesWithFamilyInfo: {
    url: MANT_URLS.articles,
    option: 'fetch-with-family-info'
  },
  addNewPresupost: {
    url: MANT_URLS.presuposts,
    option: 'add'
  },
  fetchAllProjectInfo: {
    url: MANT_URLS.projects,
    option: 'fetchAllInfo'
  },
  fetchPresupostsByStatus: {
    url: MANT_URLS.presuposts,
    option: 'fetch-by-status'
  },
  fetchPresupostDetails: {
    url: MANT_URLS.presuposts,
    option: 'fetch-details'
  },
  updatePresupostStatus: {
    url: MANT_URLS.presuposts,
    option: 'update-status'
  },
  addPresupostDoc: {
    url: MANT_URLS.presuposts,
    option: 'add-doc-url'
  },
  addTicketsMessage: {
    url: MANT_URLS.tickets,
    option: 'add'
  },
  fetchTicketsMessage: {
    url: MANT_URLS.tickets,
    option: 'fetch'
  },
  fetchAllProjects: {
    url: MANT_URLS.projects,
    option: 'fetch'
  },
  fetchAllProjectsWithRelations: {
    url: MANT_URLS.projects,
    option: 'fetch-with-relations'
  },
  fetchProjectsWithRelations: {
    url: MANT_URLS.projects,
    option: 'fetch-with-relations'
  },
  fetchProjectsCount: {
    url: MANT_URLS.projects,
    option: 'count-projects'
  },
  fetchUsers: {
    url: MANT_URLS.users,
    option: 'fetch'
  },
  addDocMessage: {
    url: MANT_URLS.docMessages,
    option: 'add'
  },
  fetchDocMessages: {
    url: MANT_URLS.docMessages,
    option: 'fetch'
  },
  fetchUserById: {
    url: MANT_URLS.users,
    option: 'fetch-by-id'
  },
  fetchPresupostDocs: {
    url: MANT_URLS.presuposts,
    option: 'fetch-docs'
  },
  fetchProjectNotificationCount: {
    url: MANT_URLS.projects,
    option: 'count-projects-notification'
  },
  sendMailNotification: {
    url: MANT_URLS.notification,
    option: 'send-mail'
  },
  createCheckout: {
    url: MANT_URLS.checkouts,
    option: 'add'
  },
  fetchCheckouts: {
    url: MANT_URLS.checkouts,
    option: 'fetch'
  },
  addInvoice: {
    url: MANT_URLS.invoices,
    option: 'add'
  },
  fetchInvoiceByPhase: {
    url: MANT_URLS.invoices,
    option: 'by-phase'
  },
  fetchFinishedProjects: {
    url: MANT_URLS.projects,
    option: 'fetch-finished-user-projects'
  },
  fetchFinalProjectUrl: {
    url: MANT_URLS.projects,
    option: 'fetch-project-urls'
  },
  fetchUserInvoices: {
    url: MANT_URLS.invoices,
    option: 'fetch-by-user'
  },
  deleteUser: {
    url: MANT_URLS.users,
    option: 'delete'
  },
  fetchAllInvoices: {
    url: MANT_URLS.invoices,
    option: 'fetch'
  },
  fetchInvoiceInfo: {
    url: MANT_URLS.invoices,
    option: 'fetch'
  },
  deleteInvoice: {
    url: MANT_URLS.invoices,
    option: 'delete'
  },
  addEvent: {
    url: MANT_URLS.events,
    option: 'add'
  },
  fetchAllEvents: {
    url: MANT_URLS.events,
    option: 'fetch'
  },
  fetchEventInfo: {
    url: MANT_URLS.events,
    option: 'fetch'
  },
  updateEvent: {
    url: MANT_URLS.events,
    option: 'update'
  },
  deleteEvent: {
    url: MANT_URLS.events,
    option: 'delete'
  },
  fetchActiveEvent: {
    url: MANT_URLS.events,
    option: 'fetch-active'
  },
  updateUserPassword: {
    url: MANT_URLS.users,
    option: 'update-password'
  },
  updateUserPasswordByEmail: {
    url: MANT_URLS.users,
    option: 'update-password-by-email'
  },
  addMultiplePool: {
    url: MANT_URLS.pool,
    option: 'add'
  },
  fetchPoolWorkers: {
    url: MANT_URLS.pool,
    option: 'fetch-workers'
  },
  fetchPoolByEmployee: {
    url: MANT_URLS.pool,
    option: 'fetch-by-employee'
  },
  updatePoolStatus: {
    url: MANT_URLS.pool,
    option: 'update-status'
  },
  fetchAllPools: {
    url: MANT_URLS.pool,
    option: 'fetch'
  },
  linkProjectEmployee: {
    url: MANT_URLS.pool,
    option: 'link'
  },
  replaceInvoice: {
    url: MANT_URLS.invoices,
    option: 'replace'
  },
  fetchUsersBasicsByName: {
    url: MANT_URLS.users,
    option: 'search-by-name'
  },
  updateProjectInfo: {
    url: MANT_URLS.projects,
    option: 'update_v2'
  },
  createQuickProject: {
    url: MANT_URLS.projects,
    option: 'quick-project'
  },
  validatePresupost: {
    url: MANT_URLS.presuposts,
    option: 'validate'
  },
  fetchAllStatusCount: {
    url: MANT_URLS.presuposts,
    option: 'fetch-all-status'
  },
  createRecoverCode: {
    url: MANT_URLS.recoverCodes,
    option: 'create-code'
  },
  verifyRecoverCode: {
    url: MANT_URLS.recoverCodes,
    option: 'verify-code'
  }
}

export default requests

// ** React
import React, { useState } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

// ** Themes
import { dark, light } from '../constants/theme'

const Ctx = React.createContext({
  message: 'No tienes acceso al contexto.'
})

const ThemeProvider = ({
  children
}) => {
  const [theme, setTheme] = useState(() => {
    if (window.localStorage) {
      const storedTheme = window.localStorage.getItem('@currentTheme')
      if (storedTheme) {
        return (storedTheme === 'light') ? light : dark
      }
    }

    return light
  })

  return (
        <StyledThemeProvider theme={theme}>
            <Ctx.Provider value={{
              theme,
              setTheme
            }}>
                { children }
            </Ctx.Provider>
        </StyledThemeProvider>
  )
}

export const useTheme = () => {
  const { theme, setTheme } = React.useContext(Ctx)

  const changeTheme = () => {
    try {
      const { title } = theme
      const newTheme = (title === 'light' ? dark : light)
      if (window.localStorage) {
        window.localStorage.setItem('@currentTheme', newTheme.title)
      }

      // ** Change state of context
      setTheme(newTheme)
    } catch (error) {
      console.error('@currentTheme', error)
    }
  }

  return [theme, changeTheme]
}

export default ThemeProvider

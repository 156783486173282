import { atom, selector } from "recoil";

const phaseState = atom({
    key: 'phaseState',
    default: null
})

const getPhaseState = selector({
    key: 'getPhaseState',
    get: ({ get }) => {
        const phase = get(phaseState)

        return phase
    }
})


export {
    // MAIN
    phaseState,
    getPhaseState
}
import styled from "styled-components";

export const Root = styled.div`
    width: 98%;
    height: max-content;
    position: absolute;
    z-index: 0;
`

export const Tooltip = styled.div`
    width: max-content;
    height: max-content;
    position: absolute;
    right: 1rem;
`

export const TooltipContent = styled.div`
    transform: translateX(${props => `${props.x || 0}px`}) translateY(${props => `${props.y || 0}px`});
    margin-left: 210px;

    z-index: 99999 !important;
`
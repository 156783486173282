const animationsCSS = `

    /* begin: FRAMER MOTION */

        .framer-container {
            display: flex;
            flex: 1;

            justify-content: center;
            align-items: center;
            
            background: var(--corporativeColor);

            padding: 3rem;
        }
      
      .framer-item {
        width: 8rem;
        overflow: visible;
        stroke: rgba(255, 255, 255, 1);
        strokeWidth: 2;
        strokeLinejoin: round;
        strokeLinecap: round;
      }

    /* end: FRAMER MOTION */

    /* begin: SLIDE ANIMATIONS */

        .slide-right {
            margin-right: -40rem;
            -webkit-animation: slide 0.3s forwards;
            -webkit-animation-delay: 0.3s;
            animation: slide 0.3s forwards;
            animation-delay: 0.3s;
        }

        @-webkit-keyframes slide {
            100% { margin-right: 0; }
        }
        
        @keyframes slide {
            100% { margin-right: 0; }
        }

        @-ms-keyframes slide {
            100% { margin-right: 0; }
        }

        @-moz-keyframes slid { 
            100% { margin-right: 0; }
        }

    /* end: SLIDE ANIMATIONS */
    
    /* begin: SPIN ANIMATION */

        @-ms-keyframes spin { 
            from { 
                -ms-transform: rotate(0deg); 
            } to { 
                -ms-transform: rotate(360deg); 
            }
        }
        @-moz-keyframes spin { 
            from { 
                -moz-transform: rotate(0deg); 
            } to { 
                -moz-transform: rotate(360deg); 
            }
        }
        @-webkit-keyframes spin { 
            from { 
                -webkit-transform: rotate(0deg); 
            } to { 
                -webkit-transform: rotate(360deg); 
            }
        }
        @keyframes spin { 
            from { 
                transform: rotate(0deg); 
            } to { 
                transform: rotate(360deg); 
            }
        }
        
    /* end: SPIN ANIMATION */


    /* begin: CSS Transition Classes */

      .menu-primary-enter {
        position: absolute;
        transform: translateX(-110%);
      }
      .menu-primary-enter-active {
        transform: translateX(0%);
        transition: all 500ms ease;
      }
      .menu-primary-exit {
        position: absolute;
      }
      .menu-primary-exit-active {
        transform: translateX(-110%);
        transition: all 500ms ease;
      }
      
      
      .menu-secondary-enter {
        transform: translateX(110%);
      }
      .menu-secondary-enter-active {
        transform: translateX(0%);
        transition: all 500ms ease;
      }
      .menu-secondary-exit {
        
      }
      .menu-secondary-exit-active {
        transform: translateX(110%);
        transition: all 500ms ease;
      }
    
    /* end: CSS Transition Classes */
    
`


export default animationsCSS
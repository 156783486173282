// ** React
import React from 'react'
import ReactDOM from 'react-dom/client'

// ** Providers
import ThemeProvider from './hooks/useTheme'
import AuthProvider from './hooks/useAuth'

// ** Components
import App from './App'


ReactDOM.createRoot(
  document.getElementById('root')
).render(
  <AuthProvider>
    <ThemeProvider>
        <App />
    </ThemeProvider>
  </AuthProvider>
)

// ReactDOM.render(
//   <AuthProvider>
//     <ThemeProvider>
//         <App />
//     </ThemeProvider>
//   </AuthProvider>,
//   document.getElementById('root')
// )

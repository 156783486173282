// React Utils
import React, { useContext, useMemo } from 'react'

// Styles
import { Root, TooltipContent } from './styles'

// Context
import { TooltipContext } from '../../context/Tooltips'

const RenderTooltip = ({ children, ...restOfProps }) => {

    return (
        <TooltipContent x={restOfProps?.x} y={restOfProps?.y}>
            {children}
        </TooltipContent>

    )
}

export default function TooltipRoot () {

    const [tooltips] = useContext(TooltipContext)

    const activeTooltip = useMemo(() => tooltips.find(t => t.active), [tooltips])

    return (
        <Root>
            <RenderTooltip active={activeTooltip !== undefined} {...activeTooltip?.viewport }>
                {activeTooltip?.renderItem()}
            </RenderTooltip>
        </Root>
    )
}
// ** React utils
import React, { Suspense, lazy, useEffect } from 'react'

// * Routing
import { BrowserRouter, useLocation } from 'react-router-dom'

// ** Components
import { ToastContainer } from 'react-toastify'
import GlobalLoading from './components/GlobalLoading'

// ** Styles
import GloblalStyle from './styles/GloblalStyle'
import 'react-toastify/dist/ReactToastify.css'
import './styles/Dropzone.css'
import { RecoilRoot, useRecoilSnapshot } from 'recoil'

// ** State
import { initRecoilState, usePersistStorage } from "./store"
import { TooltipProvider } from './context/Tooltips'

// ** Lazy Components
const LazyRouter = lazy(() => import("./router"))

function DebugObserver() {
  const snapshot = useRecoilSnapshot();
  usePersistStorage()
  
  useEffect(() => {
    console.debug('The following atoms were modified:');
    for (const node of snapshot.getNodes_UNSTABLE({isModified: true})) {
      console.debug(node.key, snapshot.getLoadable(node));
    }

  }, [snapshot]);

  return null;
}

function App() {

  useEffect(() => {
    if( !window ) return
    if( window && localStorage.getItem("@currentTheme") === "dark" ) localStorage.removeItem("@currentTheme")
  }, [])

  return (
    <>
      <RecoilRoot initializeState={initRecoilState}>
        {/* <DebugObserver /> */}
        <TooltipProvider>
          <BrowserRouter>
            <Suspense fallback={<GlobalLoading />}>
              <LazyRouter />
            </Suspense>
          </BrowserRouter>
        </TooltipProvider>
      </RecoilRoot>
      <ToastContainer />
      <GloblalStyle />
    </>
  )
}

export default App

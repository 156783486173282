import { atom, selector } from "recoil";

const breadcrumbState = atom({
    key: 'breadcrumbState',
    default: [
        {
            path: '/'
        }
    ]
})

const getBreadcrumbState = selector({
    key: 'getBreadcrumbState',
    get: ({ get }) => {
        const breadcrumbs = get(breadcrumbState)

        return breadcrumbs
    }
})

export {
    breadcrumbState,
    getBreadcrumbState
}
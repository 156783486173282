// ** React
import { useReducer } from 'react'
import TooltipRoot from '../../components/Tooltip/Root.js'

import Ctx from './Context.js'
import Reducer from './Reducer'

export const initialState = []

const State = ({ children }) => {

    const [state, dispatch] = useReducer(Reducer, initialState)

    return (
        <Ctx.Provider value={[ state, dispatch ]}>
            <TooltipRoot />
            {children}
        </Ctx.Provider>
    )
}


export default State
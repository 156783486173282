// ** React
import React, { useEffect, useState } from "react";

// * Framer Motion
import { motion } from "framer-motion";

function GlobalLoading({
    theme = "dark"
}) {    

    const  [ muchTimeWaiting, setMuchTimeWaiting ] = useState(false)

    const GlobalIcon = {
        hidden: {
          opacity: 0,
          pathLength: 0,
          fill: "rgba(255, 255, 255, 0)"
        },
        visible: {
          opacity: 1,
          pathLength: 0.065,
          fill: theme === 'dark' ? "rgba(255, 255, 255, 1)" : "#000000"
        }
    }
    
    const GlobalCircle = {
        hidden: {
          opacity: 0,
          pathLength: 0,
          fill: "rgba(255, 255, 255, 0)"
        },
        visible: {
          opacity: 1,
          pathLength: 0,
          fill: theme === 'dark' ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"
        }
      };

    useEffect(() => {
        setTimeout(() => {
            if( navigator.onLine ) return
            setMuchTimeWaiting(true)
        }, 20000);
    }, [])

    return (
        <div className="framer-container" style={{ backgroundColor: theme === 'dark' ? '' : 'transparent'}}>
            { muchTimeWaiting 
                ? <h1>Demasiada espera...</h1>
                :  <motion.svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 2084 2084"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="framer-item"
                        >
                        <g>
                            <g>
                            <motion.path
                                d="M2044.42,1041.67c-0,-553.798 -448.951,-1002.75 -1002.75,-1002.75c-553.798,-0 -1002.75,448.951 -1002.75,1002.75c0,553.798 448.951,1002.75 1002.75,1002.75c553.798,-0 1002.75,-448.951 1002.75,-1002.75"
                                vectorEffect="non-scaling-stroke"
                                variants={GlobalCircle}
                                initial="visible"
                                strokeWidth={0}
        
                            />
                            </g>
                            <g>
                            <motion.path
                                d="M1210.22,1025.58l187.907,0l0,311.351c-86.945,62.243 -206.156,95.55 -315.653,95.55c-239.463,-0 -414.394,-162.164 -414.394,-390.802c-0,-228.639 174.931,-390.802 418.696,-390.802c140.653,-0 254.452,48.295 328.491,137.46l-135.241,122.334c-51.556,-56.899 -110.607,-83.684 -182.564,-83.684c-128.787,0 -214.691,84.794 -214.691,214.692c-0,126.705 85.904,214.691 212.61,214.691c39.69,0 77.23,-7.494 114.839,-24.633l0,-206.157Z"
                                vectorEffect="non-scaling-stroke"
                                variants={GlobalIcon}
                                initial="hidden"
                                animate="visible"
                                transition={{
                                    default: { duration: 4, ease: "easeInOut", repeat: Infinity, repeatType: "reverse" },
                                    fill: { duration: 4, ease: [1, 0, 0.8, 1], repeat: Infinity, repeatType: "reverse" }
                                }}
                            />
                            </g>
                        </g>
                    </motion.svg>

            }
        </div>
    )
}


export default GlobalLoading
import bcrypt from "bcryptjs"
import crypto from "crypto-js"
import SECRET_KEY from "../config/secret"

/**
 * Method to order an array by property name.
 * @param {*} Options
 * @returns
 */
export const orderArrayByProp = ({
  data = [],
  propertyName,
  order = 'ASC'
}) => {

  if (data.length === 0 || !Array.isArray(data) || typeof order !== 'string') return

  return data.sort((a, b) => {
    return (order.toUpperCase() === 'ASC') ? a[propertyName] - b[propertyName] : a[propertyName] + b[propertyName]
  })
}

/**
 * Method to encrypt password using bcryptjs
 * @param {*} password 
 * @param {*} options 
 */
export const encryptPassword = async (password, options = { salt: 10 }) => {
  // Save salt fro options objct
  const salt = options.salt ? options.salt : 10

  // Generate salt with bcrypt
  const generatedSalt = await bcrypt.genSalt(isNaN(options.salt) ? 10 : salt)

  // Encrypt and return password with hash codification
  return await bcrypt.hash(password, generatedSalt)
}

/**
 * Method to compare recivedPassword with a hashesPassword
 * @param {*} password 
 * @param {*} encryptedPassword 
 * @returns boolean
 */
export const comparePassword = async (recivedPassword, encryptedPassword) => {
  return await bcrypt.compare(recivedPassword, encryptedPassword)
}


/**
 * Method to create Form Data of an object
 * @param {*} obj 
 * @returns 
 */
export const createFormData = (obj, options = {}) => {
  if (typeof obj === 'object') {
    const formData = new FormData()
    Object.entries(obj).forEach(([key, value]) => {
      if(options?.specialArrays && key.includes("[]")) {
        value.forEach(val => {
          if(val instanceof File){
            formData.append(key, val)
          }else{
            formData.append(key, JSON.stringify(val));
          }
        })
      } else {
        formData.append(key, value)
      }
    })
    return formData
  }

  return new FormData()
}


/**
 * Use to encrypt genera data
 * @param {*} data 
 * @param {*} options 
 */
export const encodeData = (data) => {

  if (typeof data !== "string") return

  const encodedData = crypto.AES.encrypt(data, SECRET_KEY)

  return encodedData.toString()
}


export const decodeData = (data) => {

  if (typeof data !== "string") return

  const decodedData = crypto.AES.decrypt(data, SECRET_KEY)
  const originalText = decodedData.toString(crypto.enc.Utf8)

  return originalText
}


/**
 * Change string chain aspect with multiple options.
 * @param {*} str 
 * @param {*} options 
 * @returns 
 */
export const beautifyStringChain = (str, options = {}) => {
  const { length = 5, character = '?' } = options

  if (typeof str !== 'string') {
    str = str.toString()
  }


  let strLength = str.length

  if (strLength >= length) return str

  let beautifiedStr = ""
  while (strLength < length) {
    beautifiedStr += character
    strLength++
  }


  return beautifiedStr + str

}


/**
 * Group all elements of array by an iterater
 * @param {*} array 
 * @param {*} iterater ( Function | Property )
 * @returns 
 */
export function groupBy(array, iterater) {
  const data = {}
  const isFunction = typeof iterater === 'function'
  for (const el of array) {
    const key = isFunction ? iterater(el) : el[iterater]
    data[key] ??= []
    data[key].push(el)
  }
  return data
}



export const convertBlob = async ( url ) => fetch(url).then(res => res.blob()) 



export const beautifyUrl = ( url ) => {

  const weTransferRegex = /we.tl/g

}


export const validateControl = ( element, validateType, extra = {} ) => {

  switch (validateType) {
    case 'EMAIL':
      const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return element.value.match(regex)

    case 'NOT-EMPTY':
      const value = element.value
      return value ? true : false

    case 'MAX-CHARACTERS':
      return element.value.length <= extra?.maxCharacters

    case 'MIN-CHARACTERS':
      return element.value.length >= extra?.minCharacters
    
    case 'REQUIRED':
      return element.value.trim() !== ''
    
    case 'NUMBER':
      return !(isNaN(element.value))

    default:
      return true
  }
  
}


export const beautifyPrice = ( price) => {

  if ((price / 1000000) >= 1 ) price = `${price / 1000000}M`

  if( (price / 1000) >= 1 ) price = `${price / 1000}K`

  return `${price} €`
}
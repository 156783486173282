import { initialState } from "./State";
import { CLEAN_TOOLTIPS, ADD_TOOLTIP, REMOVE_TOOLTIP, CHANGE_TOOLTIP } from "./types";

const Reducer = ( state = initialState, action ) => {
    switch (action.type) {
        case ADD_TOOLTIP:

            const { width, height, y, x, top, left, right, bottom } = action.payload.ref.current.getBoundingClientRect()

            return [
                ...state, 
                {
                    ...action.payload,
                    viewport: {
                        width,
                        height,
                        y,
                        x,
                        top,
                        left,
                        right,
                        bottom
                    }
                }
            ]
    
        case REMOVE_TOOLTIP: 
            
            const { id: tooltipId } = action.payload

            if( !Array.isArray(state) ) return []

            return state.filter(tooltip => tooltip.id !== tooltipId)
        
        case CLEAN_TOOLTIPS: 
            return []

        case CHANGE_TOOLTIP:
            const { id, ...newProps } = action.payload

            if(Array.isArray(state)){
                return state.map(tooltip => {
                    if(tooltip.id === id)  tooltip = { ...tooltip, ...newProps }

                    return tooltip
                })
            }

            return state

        default:
            return [...state]
    }
}

export default Reducer

export const light = {
  title: 'light',
  colors: {
    primary: '#173353',
    secondary: '#3287E5',
    tertiary: '#0A254E',
    quaternary: '#E4E4E4',
    quinary: '#FFFFFF',
    senary: '#000000',
    gray: '#808080',
    success: 'rgba(45, 166, 122, 1)',
    successOp: 'rgba(45, 166, 122, 0.2)',
    error: 'rgba(245, 97, 110, 1)',
    errorOp: 'rgba(245, 97, 110, 0.2)',
    warning: '#e3a200',
    warningOp: '#e3a20080'
  },
  ui: {
    buttonDisabled: {
      bg: 'rgba(211,211,211,1)'
    },
    buttonSuccess: {
      bg: 'rgba(45, 166, 122, 1)'
    }
  },
  sizes: {
    navbar: '60px',
    aside: '260px'
  }
}

export const dark = {
  title: 'dark',
  colors: {
    primary: '#173353', //
    secondary: '#3287E5',
    tertiary: '#0A254E',
    quaternary: '#132539',
    quinary: '#173353', //
    senary: '#FFFFFF', //
    gray: '#808080', //
    success: 'rgba(45, 166, 122, 1)',
    successOp: 'rgba(45, 166, 122, 0.2)',
    error: 'rgba(245, 97, 110, 1)',
    errorOp: 'rgba(245, 97, 110, 0.2)',
    warning: '#e3a200',
    warningOp: '#e3a20080'
  },
  sizes: {
    navbar: '60px',
    aside: '260px'
  }
}

// ** React
import React, { useEffect, useMemo, useState } from 'react'
import { comparePassword, createFormData, decodeData, encodeData, encryptPassword } from '../utility'

// ** Configs and Utils
import axios from "../axios" 
import requests from '../constants/requests'
import { toast } from 'react-toastify'

const Ctx = React.createContext({
  message: 'No tienes acceso al contexto.'
})

const AuthProvider = ({
  children
}) => {
  const [auth, setAuth] = useState({})

  useEffect(() => {

    if( window ){
      if( window.localStorage && window.localStorage.getItem("@currentUser") ){
        const storedUser = window.localStorage.getItem("@currentUser")

        const currentUser = JSON.parse( decodeData(storedUser) )

        setAuth({
          currentUser,
          roles: getRoles( currentUser.role_id )
        })
      }
    }


  }, [])

  return (
    <Ctx.Provider value={{
      auth,
      setAuth
    }}>
        { children }
    </Ctx.Provider>
  )
}

export const ROLES = {
  USER: '1',
  WORKER: '2',
  ADMIN: '3',
  SUPERADMIN: '4'
}

export const ROLE_KEY_ASSIGNATION = {
  'isUser': ROLES.USER,
  'isWorker': ROLES.WORKER,
  'isAdmin': ROLES.ADMIN,
  'isSuperAdmin': ROLES.SUPERADMIN
}

const getRoles = ( _role_id ) => {
  return {
    isUser: _role_id === ROLES.USER,
    isWorker: _role_id === ROLES.WORKER,
    isAdmin: _role_id === ROLES.ADMIN,
    isSuperAdmin: _role_id === ROLES.SUPERADMIN
  }
}

export const useAuth = () => {
  const { auth, setAuth } = React.useContext(Ctx)

  const logIn = async ({ email, password, rememberMe} ) => {

    const { url, option } = requests.fetchUserWithEmail

    const rq = await axios.post( url, createFormData({
      email,
      option
    }))

    const { data } = rq

    try {
      const { status, response } = data

      if( !status ){
        toast.error('¡Ups!¡ Parece que ha surgido un problema.')
        return
      }

      const matchPasswords = await comparePassword(password, response.password)

      if( !matchPasswords ) {
        toast.error('¡El usuario o la contraseña no coinciden!')
        return
      }


      if( rememberMe && window ){
        if( window.localStorage ){

          const stringifiedResponse = JSON.stringify(response)
          window.localStorage.setItem("@currentUser", encodeData(stringifiedResponse) )
        }
      }

      toast.info(`¡Bienvenido/a ${response.full_name}!`)
      setAuth(
        {
          ...auth, 
          currentUser: response,
          roles: getRoles( response.role_id )
        }
        
      )


    } catch ( err ) {
      console.error("@useAuth - LogIn, ", err)
      toast.error(`¡Ha surgido un problema. Por favor, contacte con un administrador.!`)
    }

  }

  const changeRole = ( newRole ) => {
    
    if(!auth?.currentUser) throw new Error('No puedes ejecutar esta acción sin estar autenticado')

    const userRoles = getRoles(auth.currentUser.role_id)

    if(!userRoles.isSuperAdmin) throw new Error('No puedes realizar esta acción con tus permisos actuales')

    const roles = getRoles(`${newRole}`)

    if(Object.values(roles).every(x => false)) throw new Error('No has seleccionado un rol válido')

    setAuth({
      ...auth,
      roles
    })
  }

  const modifyUser = ( newUser ) => {
    setAuth({
      ...auth,
      currentUser: newUser
      // roles: getRoles( newUser.role_id )
    })

    if( localStorage && localStorage["@currentUser"] ){
      const stringifiedResponse = JSON.stringify(newUser)
      localStorage.setItem("@currentUser", encodeData(stringifiedResponse) )
    }
  }

  const signIn = async ({
    fullname,
    email,
    password,
    number
  }) => {

    const encryptedPassword = await encryptPassword(password)

    const { url, option } = requests.createUser
    const rq = await axios.post( url , createFormData({
      option,
      fullname,
      email,
      number,
      password: encryptedPassword
    }))
    
    const { data } = rq
    
    try {
      const { status, message } = data
      
      if( !status ){
       toast.error(message)
       return
      }

      const { response: currentUser } = data
      
      toast.success( message )
      setAuth({
        ...auth,
          currentUser,
          roles: getRoles( currentUser.role_id )
      })

    } catch ( err ) {
      console.error("@useAuth - SignIn, ", err)
      toast.error(`¡Ha surgido un problema. Por favor, contacte con un administrador.!`)
    }
    
    return
  }

  const logOut = () => {
    if( window ){
      if( window.localStorage && window.localStorage.getItem("@currentUser") ){
        window.localStorage.removeItem("@currentUser")
      }
    }
    setAuth({})
  }

  const checkIfIsSuperAdmin = useMemo(() => {
    if(!auth?.currentUser || !auth?.currentUser?.role_id ) return false

    const { isSuperAdmin } = getRoles(auth.currentUser.role_id)

    return isSuperAdmin
  }, [auth.currentUser])

  return {
    auth,
    checkIfIsSuperAdmin,
    logIn,
    signIn,
    logOut,
    modifyUser,
    changeRole
  }
}

export default AuthProvider

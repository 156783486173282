// ** State
import { useRecoilTransactionObserver_UNSTABLE } from "recoil"
import { breadcrumbState } from "./breadcrumb"
import { phaseState } from "./phase"
import { projectState } from "./project"

const GLOBAL_STORAGE_NAME = "@GlobalStation"
function initRecoilState(snapshot) {

    const data = localStorage.getItem(GLOBAL_STORAGE_NAME)
    if (!data) return

    const {
        phase: persistedPhase,
        project: persistedProject,
        breadcrumb: persistedBreadcrumb
    } = JSON.parse(data)


    if (persistedPhase) snapshot.set(
        phaseState,
        persistedPhase
    )

    if (persistedProject) snapshot.set(
        projectState,
        persistedProject
    )

    if (persistedBreadcrumb) snapshot.set(
        breadcrumbState,
        persistedBreadcrumb
    )

}


const usePersistStorage = () => useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => processSnapshot(snapshot))

async function processSnapshot(snapshot) {
    const persistedProject = await snapshot.getPromise(projectState)
    const persistedPhase = await snapshot.getPromise(phaseState)
    const persistedBreadcrumb = await snapshot.getPromise(breadcrumbState)


    localStorage.setItem(
        GLOBAL_STORAGE_NAME,
        JSON.stringify({
            phase: persistedPhase,
            project: persistedProject,
            breadcrumb: persistedBreadcrumb
        })
    )
}


export {
    initRecoilState,
    usePersistStorage
}
import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'

// ** Animations CSS
import animationsCSS from './CSSanimations'

export default createGlobalStyle`

    /* begin: Montserrat font types */
        /* @font-face {
            font-family: "Montserrat";
            font-weight: 100;
            src: url('../fonts/Montserrat-Thin.otf') format("opentype");
        }
        
        @font-face {
            font-family: "Montserrat";
            font-weight: 200;
            src: url('../fonts/Montserrat-Light.otf') format("opentype");
        }

        @font-face {
            font-family: "Montserrat";
            font-weight: 300;
            src: url('../fonts/Montserrat-Regular.otf') format("opentype");
        }

        @font-face {
            font-family: "Montserrat";
            font-weight: 400;
            src: url('../fonts/Montserrat-Medium.otf') format("opentype");
        }

        @font-face {
            font-family: "Montserrat";
            font-weight: 500;
            src: url('../fonts/Montserrat-SemiBold.otf') format("opentype");
        } */
    /* end: Montserrat font types */
        
    /* begin: Extra font types */
        @font-face {
            font-family: "Acumin";
            src: url('../fonts/AcuminVariableConcept.otf') format("opentype");
        }

        @font-face {
            font-family: "MyriadPro";
            src: url('./assets/MyriadPro-Regular') format("otf");
        }
    /* end: Extra font types */

        
    /* begin: Global CSS Configuration */
        * {
            box-sizing: border-box;
        }

        body {
            margin: 0;
            font-family: "Montserrat", sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            
            background-color: var(--bgColor);
            color: var(--fontColor);
        }

        textarea {
            font-family: "Montserrat", sans-serif;

        }

        
        code {
            font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        p {
            padding: 0;
            margin: 0;
        }

        p {
            font-weight: 500;
        }

        ul { padding: 0; }

        li { list-style: none; }

        a {
            &.light {
                color: var(--lightColor);
            }

            &.gray {
                color: var(--lightAltColor);
            }
            text-decoration: none;
        }

        ::-webkit-scrollbar {
            width: 0.5em;

            &-track {
                background: var(--lightAltColor);
            }

            &-thumb {

                background: var(--corporativeColor);
                opacity: 0.3;

                transition: opacity var(--normalTransition);
                
                &:hover {
                    opacity: 1;
                }
            }
        }

    /* end: Global CSS Configuration */

    /* begin: Root Variables */
        :root {
            /* UI COLORS */
            --bgColor: ${(props) => props.theme.colors.quaternary};
            --fontColor: ${(props) => props.theme.colors.senary};

            --lightColor: ${(props) => props.theme.colors.quinary};
            --lightAltColor: ${(props) => props.theme.colors.gray};
            
            --darkColor: ${(props) => props.theme.colors.senary};

            --successColor: ${(props) => props.theme.colors.success};
            --successColorOp: ${(props) => props.theme.colors.successOp};
            --errorColor: ${(props) => props.theme.colors.error};
            --errorColorOp: ${(props) => props.theme.colors.errorOp};
            --warningColor: ${(props) => props.theme.colors.warning};
            --warningColorOp: ${(props) => props.theme.colors.warningOp};


            --corporativeColor: ${(props) => props.theme.colors.primary};
            --corporativeColorOp: #1733531c;
            --corporativeColorOpDark: #20467130;
            --secondaryColor: ${(props) => props.theme.colors.secondary};

            /* UI SIZES */
            --navbarSize: ${(props) => props.theme.sizes.navbar};
            --asideSize: ${(props) => props.theme.sizes.aside};

            /* UI COLORS */
            --btn-success-bg: ${(props) => props.theme.ui.buttonSuccess.bg};
            --btn-disabled-bg: ${(props) => props.theme.ui.buttonDisabled.bg};

            --dropdownWidth: 300px;

            --rounded: 6px;

            --bgBoxColor: #F2F2F2;

            /* TRANSITIONS */
            --normalTransition: 0.3s ease 0s;

        }
    /* end: Root Variables */


    /* begin: Animations */
    ${animationsCSS}
    /* end: Animations */
`

export const GeneralPageTitle = styled.span`
    font-size: 2.5em;
    color: ${props => props.isLight ? 'var(--lightColor)' : 'var(--fontColor)'};
    font-weight: 600;
    
    > small {
        font-weight: 600;
        font-size: 0.6em;
        margin-inline-start: 5px;
    }
`

export const NormalContainer = styled.div`
    padding: 40px;
    width: 100%;
    height: max-content;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    /* border: 1px solid red; */

    &.row {
        flex-direction: row;
    }
`

export const CenterInPageContainer = styled.div`
    display: grid;
    place-content: center;
    width: 60%;
    height: 100vh;
    margin: auto;
`

export const CenteredContainer = styled.div`
    width: 70%;
    height: max-content;

    margin-left: auto;
    margin-right: auto;

    padding: 40px;
`
export const BlankBox = styled.div`
    display: block;
    flex-direction: column;

    gap: 2rem;

    width: 100%;
    height: max-content;

    background-color: var(--lightColor);
    border-radius: var(--rounded);

    padding: 30px;
`

export const BlankBoxTitle = styled.h3`
    font-size: 1em;
    color: var(--fontColor);
    font-weight: ${(props) => props?.bold ? 600 : 400};
`

export const BoxPadding = styled.div`
    padding: ${props => props.size || '10px'};
`

export const BoxTitle = styled.h3`
    font-size: 0.7em;
    color: var(--fontColor);
    font-weight: 600;

    text-transform: uppercase;
`

export const BoxText = styled.span`
    font-size: 0.7em;
    color: var(--fontColor);
    font-weight: 300;

    small {
        &.gray {
            color: var(--lightAltColor);
        }
    }
`

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--lightColor);

    /* border: 1px solid red; */
    border-radius: var(--rounded);
    overflow: hidden;

    max-width: ${(props) => props?.isSmall ? '140px' : '240px'};
    /* max-width: 240px; */



    gap: 5px;
    cursor: pointer;

    svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 2px;

        color: var(--fontColor);
        margin-bottom: 1rem;
    }

    &:hover img {
        transform: scale(1.05);
    }
`

export const BoxImageWrapper = styled.div`
    width: 100%;
    height: 120px;

    > img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;

        transition: var(--normalTransition);
    }
`

export const Head = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`
export const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4rem;
`

export const Row = styled.div`
    display: flex;

    align-items: center;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;

    p {
        font-weight: 200;
    }
`

export const NormalButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 0.3rem;

    padding: 0.4rem 0.9rem;
    width: max-content;

    font-size: 0.8em;
    font-weight: 300;
    color: var(--fontColor);

    background-color: transparent;


    border: 1px solid var(--lightAltColor);
    outline: none;

    border-radius: var(--rounded);

    cursor: pointer;

    opacity: 0.8;
    transition: opacity var(--normalTransition);

    svg {
        width: 1rem;
        height: 1rem;

        color: var(--fontColor);
    }

    &:hover {
        opacity: 1;
    }

    &:active {
        opacity: 0.4;
    }
`

export const RoundedButton = styled.button`
    font-size: 0.8em;
    font-weight: 400;

    outline: none;
    border: 0;

    border-radius: 5555px;
    padding: 15px 20px;

    cursor: pointer;

    color: var(--fontColor);
    background-color: var(--lightColor);
    
    transition: opacity var(--normalTransition);

    &:active {
        opacity: 0.8;
    }
`

export const IconsExpositionWrapper = styled.div`
padding: 4rem;
    width: calc(100% - 8rem);
    margin: 3rem auto;
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: auto;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        width: 100%;
        height: 100%;
        padding: 0.5rem 0;
        border-radius: var(--rounded);
        transition: background-color 0.5s ease 0s;


        &:hover {
            background-color: var(--lightColor);
            cursor: help;
        }

        svg {
            width: 30px;
        }
    }

`

export const LoadingBox = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1em;
    opacity: 0.3;

    svg {
        width: 1em;
        animation-name: spin;
        animation-duration: 5000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
        /* transform: rotate(3deg); */
    }

`


export const SmallMessage = styled.span`
    font-size: 0.85em;
    color: var(--lightAltColor);
    font-weight: 500;
`

export const ScrollBox = styled.div`

    display: flex;
    
    width: 100%;
    padding-bottom: 1rem;

    gap: ${props => props?.space ? props.space : '1rem'};
    overflow-y: ${(props) => props?.vertical ? 'auto' : 'hidden'};
    overflow-x: ${(props) => props?.horizontal ? 'auto' : 'hidden'};

    margin-top: ${props => props?.marginTop ? props.marginTop : 0};
    margin-bottom: ${props => props?.marginBottom ? props.marginBottom : 0};

`


export const NormalLink = styled(Link)`
    font-weight: 400;
    font-size: 0.8em;
    width: max-content;

    color: var(--secondaryColor);

    &:hover {
        text-underline-offset: 2px;
        text-decoration: underline;
        text-decoration-color: var(--secondaryColor);
    }
`

export const FakeTableButton = styled.button`
    width: max-content;
    height: max-content;

    border-radius: var(--rounded);
    padding: 10px 20px;

    margin-left: 5px;

    border: 0;
    outline: none;

    background-color: var(--lightColor);
    color: var(--fontColor);
    font-weight: 600;

    &:disabled {
        opacity: 0.3;
    }

    &:active {
        opacity: 0.85;
    }

    cursor: pointer;
`

export const BackButton = styled.button`
    width: max-content;
    border:  0;
    outline: none;
    padding: 0;

    color: var(--fontColor);
    font-weight: 600;
    font-size: 1em;

    span {
        display: flex;
        align-items: center;
        gap: 5px;
        opacity: 0.3;

        transition: var(--normalTransition);
    }

    cursor: pointer;

    background-color: transparent;

    &:hover {
        span {
            opacity: 1;
        }
    }
`
export const AccorditionButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;
    
    outline: none;
    border: 0;
    background: transparent;

    font-size: 1em;
    font-weight: 400;

    color: var(--lightAltColor);

    cursor: pointer;

    transition: var(--normalTransition);

    svg {
        height: 1rem;
        width: 1rem;
    
        color: var(--lightAltColor);
    }

    &:hover { 
        color: var(--fontColor); 
        
        svg {
            color: var(--fontColor); 
        }
    
    }
`



export const DownloadTransparentButton = styled.button`

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid var(--corporativeColor);
    background-color: transparent;
    width: 26px;
    height: 26px;

    border-radius: calc(var(--rounded) * 0.3);
    transition: var(--normalTransition);
    cursor: pointer;
    
    > svg {
        height: 100%;
        color: var(--corporativeColor);
    }

    &:hover {
        border: 1px solid var(--lightColor);
        background-color: var(--corporativeColor);
        > svg {
            color: var(--lightColor);
        }
    }


`

export const GreenButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;
    border: 0;

    background: var(--successColor);
    padding: 6px 10px;
    border-radius: var(--rounded);
    color: var(--lightColor);
    font-weight: 600;

    gap: 10px;

    margin-right: 10px;
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;
    }
`

export const BlueButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;
    border: 0;

    background: var(--corporativeColor);
    padding: 6px 10px;
    border-radius: var(--rounded);
    color: var(--lightColor);
    font-weight: 600;

    gap: 10px;

    margin-right: 10px;
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;
    }

    &:disabled {
        opacity: 0.75;
    }
`

export const YellowButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;
    border: 0;

    background: var(--warningColor);
    padding: 6px 10px;
    border-radius: var(--rounded);
    color: var(--lightColor);
    font-weight: 600;

    gap: 10px;

    margin-right: 10px;
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;
    }

    &:disabled {
        opacity: 0.75;
    }
`

export const RedButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;
    border: 0;

    background: var(--errorColor);
    padding: 6px 10px;
    border-radius: var(--rounded);
    color: var(--lightColor);
    font-weight: 600;

    gap: 10px;

    margin-right: 10px;
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;
    }

    &:disabled {
        opacity: 0.75;
    }
`

export const CloseButton = styled.button`
    width: 30px;
    height: 30px;

    outline: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    
    color: var(--fontColor);
    cursor: pointer;

    > i {

        transform: rotateZ(45deg);
        
        svg {
            width: 100%;
            height: 100%;
        }
    }
`

export const FormLink = styled.a`
    color: var(--secondaryColor);
    cursor: pointer;

    transition: var(--normalTransition);

    &:hover {
        color: var(--corporativeColor);
    }
`

export const FormSelection = styled.select`
    display: flex;
    flex: 1;
    padding: 10px;
    border: 1px solid var(--darkColor);
    border-radius: var(--rounded);
`

export const FormValidationButton = styled.button`
    display: flex;
    flex: 1;
    padding: 10px;
    border: 1px solid var(--darkColor);
    border-radius: var(--rounded);


    background-color: ${props => props.isValid ? 'var(--successColor)' : 'var(--errorColor)'};

    cursor: pointer;
`

export const FormControl = styled.input`

    &[type='text'],
    &[type='email'],
    &[type='password'],
    &[type='number'] {

        display: flex;
        flex: 1;
        padding: 10px;
        border: 1px solid var(--darkColor);
        border-radius: var(--rounded);

        color: var(--fontColor);

        transition: border var(--normalTransition);

        &:focus {
            border: 1px solid var(--corporativeColor);
        }

        &::placeholder {
            color: var(--fontColor);
            opacity: 0.4;
        }

        &:read-only {
           background-color: var(--bgColor);
           border: 1px solid var(--bgAltColor);
           box-shadow: inset 0 0 20px 1px rgba(0,0,0,0.1);

           cursor: help;
        }

        &.transparent {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid var(--fontColor);
            opacity: 0.4;

            transition: 0.3s ease 0s;

            &:focus {
                outline: none;
                opacity: 1;
            }

            &::placeholder {
                opacity: 1;
            }
        }
    }

    &[type='file'] {
        padding: 10px 0;
    }
`

export const Title = styled.span`
    font-size: 1.1em;
    font-weight: 600;

    color: var(--fontColor);
    
    text-decoration: uppercase;
    margin-top: 1rem;
    margin-bottom: 10px;
`

export const SubTitle = styled.span`
    font-size: 1em;
    font-weight: 400;

    color: var(--fontColor);
`


import { atom, selector } from "recoil";

const projectState = atom({
    key: 'projectState',
    default: null
})

const getProjectState = selector({
    key: 'getProjectState',
    get: ({ get }) => {
        const project = get(projectState)
        const selected_interior = get(selectedInteriorState)

        return {
            ...project,
            selected_interior: selected_interior || project.selected_interior
        }
    }
})


// ** Property of different Phase

// const projectInteriorState = selector({
//     key: 'getProjectInteriorState',
//     get: ({ get }) => {
//         const project = get(getProjectState)
        
//         return ( !project?.selected_interior ) ? 0 : project.selected_interior
//     },
//     set: ({ get, set}, newValue) => {

//         if( isNaN( newValue) ) return

//         const currentProject = get(projectState)
//         set(projectState, {
//             ...currentProject,
//             selected_interior: newValue
//         })
//     }
// })


const selectedInteriorState = atom({
    key: 'selctedInteriorState',
    default: 0
})

const getSelectedInteriorState = selector({
    key: 'getSelectedInteriorState',
    get: ({ get }) => {
        const si = get(selectedInteriorState)

        return si
    }
})

const setSelectedInteriorState = selector({
    key: 'setSelectedInteriorState',
    get: ({get}) => get(selectedInteriorState),
    set: ({set}, newValue) => {
        set(selectedInteriorState, newValue)
    }
    
})




export {
    projectState,
    getProjectState,

    // projectInteriorState,
    selectedInteriorState,
    getSelectedInteriorState,
    setSelectedInteriorState
}